<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <div class="cont_title">我要充值</div>
          <router-link to="/vipCenter/rechargeRecord" class="toRecord">充值记录</router-link>
          <div class="rech_flex">
            <div class="rech">充值金额:</div>
            <input
              type="text"
              placeholder="请输入金额"
              v-model="moneyValue"
              class="money"
              autocomplete="off"
              @input="formatValue(moneyValue)"
            />
          </div>
          <div class="functions">
            <div class="rech">充值方式:</div>
            <div class="pay">
              <label for="radio1" class="pay_fun" v-if="type.wxpay == 1">
                <img
                  src="../../../../assets/img/weixin.png"
                  class="fun_tu"
                  alt=""
                />
                <div class="fun_zi">微信支付</div>
                <input
                  type="radio"
                  v-model="value"
                  :value="1"
                  id="radio1"
                  name="pay"
                  class="fun_radio"
                />
              </label>
              <label for="radio2" class="pay_fun" v-if="type.alipay == 1">
                <img
                  src="../../../../assets/img/zhifubao.png"
                  class="fun_tu"
                  alt=""
                />
                <div class="fun_zi">支付宝支付</div>
                <input
                  type="radio"
                  v-model="value"
                  :value="2"
                  id="radio2"
                  name="pay"
                  class="fun_radio"
                />
              </label>
              <label for="radio3" class="pay_fun" v-if="type.offline_pay == 1">
                <img
                  src="../../../../assets/img/zhuanzhang.png"
                  class="fun_tu"
                  alt=""
                />
                <div class="fun_zi">转账充值</div>
                <input
                  type="radio"
                  v-model="value"
                  :value="3"
                  id="radio3"
                  name="pay"
                  class="fun_radio"
                />
              </label>
            </div>
          </div>
          <button class="btns" @click="btns">充值</button>
          <div class="guize">
            <div style="font-weight: bold">充值规则:</div>
            <div>
              <p>
                1.汇成国际原则上不允许非本人充值情况发生，一经发现，资
                金将扣取手续费后退回充值的银行卡；
              </p>
              <p>2.在线支付的每日充值限额以各银行限额为准;</p>
              <p>
                3.严禁利用充值功能进行信用卡套现、转账、洗钱等行为，
                一经发现，资金将退回原卡并封停账号30天；
              </p>
              <p>
                4.在线充值可能存在延迟现象，一般5到10分钟内会到账，充
                值完成后，您可以进 入资金明细查询详情，如有问题，请 咨询客服。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal='false'
      :close-on-press-escape="false"
      title="扫码支付"
      :visible.sync="dialogVisible"
      width="30%"
      center
      :show-close="false"
    >
      <vue-qr :text="payQr" :size="100" :margin="2"></vue-qr>
      <p style="margin-top: 10px">支付{{ moneyValue }}元</p>
      <!-- <button type="button" class="pay-finish" @click="payFinish">
        支付完成
      </button> -->
    </el-dialog>
    <div ref="alipayWap" v-html="alipay" />
  </div>
</template>

<script>
import { wxPay, aliPay, getWxPay } from "@network/pay";
import { getRechargeOpen } from "@network/recharge";
export default {
  name: "Recharge",
  data() {
    return {
      moneyValue: "",
      value: "",
      dialogVisible: false,
      payQr: "",
      alipay: "",
      type: {},
    };
  },
  created() {
    this.getRechargeOpen();
  },
  components: {},

  methods: {
    payFinish() {
      this.dialogVisible = false;
    },
    //充值 已开启项
    getRechargeOpen() {
      getRechargeOpen().then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        } else {
          this.type = res.data;
        }
      });
    },

    btns() {
      console.log(this.value);
      if (this.value == "") {
        this.$message.error({
          message: "请选择一个充值方式充值",
          offset: 300
        });
        return false;
      } else if (this.value == 3) {
        this.$router.push({ path: "/vipCenter/rechargeForm" });
        return false;
      } else if (this.value == 1 || this.value == 2) {
        if (this.moneyValue == "" || parseFloat(this.moneyValue) <= 0) {
          this.$message.error({
            message: "请输入金额",
            offset: 300
          });
          return false;
        }
        if (this.value == 1) {
          wxPay(this.moneyValue * 100)
            .then((res) => {
              console.log(res);
              if (res.code != 0) {
                this.$message.error({
                  message: res.msg,
                  offset: 300
                });
                return false;
              }
              this.payQr = res.data.url;
              this.dialogVisible = true;
              const timer = setInterval(() => {
                getWxPay(res.data.id).then((res) => {
                  console.log(res);
                  if(res.code!=0){
                    return false;
                  }
                  if(res.data){
                    this.$message.success({message:'支付成功',offset: 300});
                    clearInterval(timer);
                    this.dialogVisible = false;
                  }
                });
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.value == 2) {
          //支付宝
          aliPay(this.moneyValue * 100)
            .then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.alipay = res.data;
                this.$nextTick(() => {
                  this.$refs.alipayWap.children[0].submit();
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    formatValue(val) {
      val = val.replace(/(^\s*)|(\s*$)/g, "");
      if (!val) return (this.moneyValue = "");
      val = val.replace(/[^\d.]/g, "");
      val = val.replace(/^\./g, "");
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      this.moneyValue = val;
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../../assets/css/vipCenter/ChildComps/recharge/personal.less";
.bodys /deep/ .el-dialog__body {
  text-align: center;
}
.pay-finish {
  margin: 10px 0 0;
  cursor: pointer;
  padding: 8px 12px;
}
</style>
