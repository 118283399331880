import {request} from './request'

export function wxPay(amount){
    return request({
        method:'post',
        url:'/mine/recharge/wechatNative',
        data:{
            amount
        }
    })
}
//获取微信成功信息
export function getWxPay(id){
    return request({
        method:'get',
        url:`checkOrder/wxPay?id=${id}`,
    })
}
//支付包
export function aliPay(amount){
    return request({
        method:'post',
        url:'/mine/recharge/alipayNative',
        data:{
            amount
        }
    })
}